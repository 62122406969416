import axios from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'

import { AUTH_DATA_COOKIE_KEY } from '@/common/utils/login'
import { QUOTE_REQUEST_TOPICS } from '@/common/utils/quoteRequestTopics'
import { AppStateEnum } from '@/enums'
import ImAuth from '@/imanage/views/ImAuth'
import AuthByToken from '@/login/views/AuthByToken'
import DownloadFromToken from '@/login/views/DownloadFromToken'
import SamlAcs from '@/login/views/SamlAcs'
import Payment from '@/payment/Payment'
import PaymentCancel from '@/payment/PaymentCancel'
import PaymentSuccess from '@/payment/PaymentSuccess'
import QuestionAnswerLayout from '@/project/layouts/QuestionAnswerLayout'
import EnvelopeTemplateLayout from '@/project/signing-checklist/envelope-templates/layouts/EnvelopeTemplateLayout.vue'
import EnvelopeTemplateAdd from '@/project/views/EnvelopeTemplateAdd.vue'
import Logs from '@/project/views/Logs'
import QuestionAnswerAdd from '@/project/views/QuestionAnswerAdd'
import QuestionAnswerItem from '@/project/views/QuestionAnswerItem'
import QuestionAnswerList from '@/project/views/QuestionAnswerList'
import { SET_APP_STATE } from '@/store/modules/global/mutation_types'
import { SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG } from '@/store/modules/subscription/mutation_types'
import { SET_ROUTER_HISTORY } from '@/store/mutation_types'
import SubscriptionBilling from '@/subscriptions/views/SubscriptionBilling.vue'
import SubscriptionClosedProjects from '@/subscriptions/views/SubscriptionClosedProjects.vue'
import SubscriptionProjects from '@/subscriptions/views/SubscriptionProjects.vue'
import SubscriptionSubscribers from '@/subscriptions/views/SubscriptionSubscribers.vue'
import SubscriptionTeams from '@/subscriptions/views/SubscriptionTeams.vue'

import ArchivesLayout from '../archives/layouts/ArchivesLayout.vue'
import Archives from '../archives/views/Archives.vue'
import ArchivesAdd from '../archives/views/ArchivesAdd.vue'
import CerteuropeSign from '../certeurope-sign/CerteuropeSign'
import NotFound from '../Error/views/NotFound'
import i18n from '../i18n'
import ActivateAccount from '../login/views/ActivateAccount'
import ForgotPassword from '../login/views/ForgotPassword'
import Logout from '../login/views/Logout'
import ResetPassword from '../login/views/ResetPassword'
import SignIn from '../login/views/SignIn'
import TwoFactor from '../login/views/TwoFactor'
import Profile from '../profile/layouts/Profile.vue'
import EmailAlias from '../profile/views/EmailAlias.vue'
import IdentityChecking from '../profile/views/IdentityChecking.vue'
import Password from '../profile/views/Password.vue'
import PersonalInformation from '../profile/views/PersonalInformation.vue'
import Settings from '../profile/views/Settings.vue'
import Bibles from '../project/bibles/Bibles'
import Documents from '../project/documents/Documents'
import SigningChecklistLayout from '../project/layouts/SigningChecklistLayout'
import Members from '../project/members/Members'
import SignedDocuments from '../project/signed-documents/SignedDocuments'
import Checklist from '../project/views/Checklist'
import Project from '../project/views/Project.vue'
import ProjectSettings from '../project/views/ProjectSettings'
import SigningChecklist from '../project/views/SigningChecklist'
import SigningChecklistAdd from '../project/views/SigningChecklistAdd'
import SigningChecklistOrder from '../project/views/SigningChecklistOrder'
import SigningView from '../project/views/SigningView'
import WatermarkView from '../project/views/WatermarkView'
import Projects from '../projects/views/Projects.vue'
import LandingPage from '../sign/views/LandingPage'
import store from '../store/'
import Subscription from '../subscriptions/views/Subscription.vue'
import WopiPage from '../wopi/views/WopiPage.vue'

/** BEGIN Routes names */
export const ROOMS_ROUTE_NAME = 'rooms-listing'
// Virtual route. There is nothing there and it only renders its children
export const NO_AUTH_ROUTE_NAME = 'no-auth'
export const SIGN_IN_ROUTE_NAME = 'sign-in'
export const LOGOUT_ROUTE_NAME = 'log-out'
export const FORGOT_PASSWORD_ROUTE_NAME = 'forgot-password'
export const ACTIVATE_ACCOUNT_ROUTE_NAME = 'activate-account'
export const RESET_PASSWORD_ROUTE_NAME = 'reset-password'
export const IM_AUTH_ROUTE_NAME = 'im-auth'
export const SAML_ACS_ROUTE_NAME = 'saml-acs'
export const TWO_FACTOR_ROUTE_NAME = 'two-factor'

/** Subscription */
export const SUBSCRIPTION_ROUTE_NAME = 'subscription'
export const SUBSCRIPTION_BILLING_ROUTE_NAME = 'subscription-billing'
export const SUBSCRIPTION_SUBSCRIBERS_ROUTE_NAME = 'subscription-subscribers'
export const SUBSCRIPTION_TEAMS_ROUTE_NAME = 'subscription-teams'
export const SUBSCRIPTION_PROJECTS_ROUTE_NAME = 'subscription-projects'
export const SUBSCRIPTION_CLOSED_PROJECTS_ROUTE_NAME = 'subscription-closed-projects'
/** Profile */
export const PROFILE_ROUTE_NAME = 'profile'
export const PROFILE_PERSONAL_INFORMATION_ROUTE_NAME = `${PROFILE_ROUTE_NAME}-personal-information`
export const PROFILE_EMAIL_ALIAS_ROUTE_NAME = `${PROFILE_ROUTE_NAME}-email-alias`
export const PROFILE_IDENTITY_CHECKING_ROUTE_NAME = `${PROFILE_ROUTE_NAME}-identity-checking`
export const PROFILE_ELECTRONIC_SIGNATURE_ROUTE_NAME = `${PROFILE_ROUTE_NAME}-electronic-signature`
export const PROFILE_PASSWORD_ROUTE_NAME = `${PROFILE_ROUTE_NAME}-password`
export const PROFILE_SETTINGS_ROUTE_NAME = `${PROFILE_ROUTE_NAME}-settings`
/** Room */
export const ROOM_ROUTE_NAME = 'room'
export const ROOM_SETTINGS_ROUTE_NAME = `${ROOM_ROUTE_NAME}-settings`
export const ROOM_MEMBERS_ROUTE_NAME = `${ROOM_ROUTE_NAME}-members`
export const ROOM_DOCUMENTS_ROUTE_NAME = `${ROOM_ROUTE_NAME}-documents`
export const ROOM_WATERMARK_VIEW_ROUTE_NAME = `${ROOMS_ROUTE_NAME}-watermark`
export const ROOM_CHECKLIST_ROUTE_NAME = `${ROOM_ROUTE_NAME}-checklist`
export const ROOM_CHECKLIST_DELETION_VALIDATION_ROUTE_NAME = `${ROOM_ROUTE_NAME}-checklist-deletion-validation`
export const ROOM_SIGNED_DOCUMENTS_ROUTE_NAME = `${ROOM_ROUTE_NAME}-signed-documents`
export const ROOM_BIBLES_ROUTE_NAME = `${ROOM_ROUTE_NAME}-bibles`
export const ROOM_LOGS_ROUTE_NAME = `${ROOM_ROUTE_NAME}-logs`
/** Folder */
export const FOLDER_ROUTE_NAME = 'folder'
/** Signing-checklist */
export const SIGNING_CHECKLIST_ROUTE_NAME = 'signing-checklist'
export const SIGNING_CHECKLIST_ADD_ROUTE_NAME = `${SIGNING_CHECKLIST_ROUTE_NAME}-add`
export const SIGNING_CHECKLIST_ORDER_ROUTE_NAME = `${SIGNING_CHECKLIST_ROUTE_NAME}-order`
/** Question Answer */
export const QUESTION_ANSWER_LIST_ROUTE_NAME = 'question-answer-list'
export const QUESTION_ANSWER_ADD_ROUTE_NAME = 'question-answer-add'
export const QUESTION_ANSWER_ITEM_ROUTE_NAME = 'question-answer-item'
/** Signature */
export const SIGNING_VIEW_ROUTE_NAME = 'signing-view'
// Separate signing view because we cannot be inside the room at that time
export const NDA_SIGNING_VIEW_ROUTE_NAME = 'nda-signing-view'
/** Archives */
export const ARCHIVES_ROUTE_NAME = 'archives'
export const ARCHIVES_ADD_ROUTE_NAME = `${ARCHIVES_ROUTE_NAME}-add`
/** Certeurope sign **/
export const CERTEUROPE_SIGN_ROUTE_NAME = 'certeurope-sign'
/** Error */
export const NOT_FOUND_ROUTE_NAME = 'not-found'
/** Common sign */
export const SIGN_LANDING_PAGE_ROUTE_NAME = 'sign-landing-page'
export const SIMPLE_SIGNATURE_ROUTE_NAME = 'simple-signature'
export const NOTIFICATIONS_PREFERENCES_ROUTE_NAME = 'notifications-preferences'
/** Download routes */
export const SIGNED_DOCUMENT_DOWNLOAD_ROUTE_NAME = 'signed-document-download'
export const PDF_FILE_DOWNLOAD_ROUTE_NAME = 'pdf-file-download'
export const PDF_FULL_FILE_DOWNLOAD_ROUTE_NAME = 'pdf-full-file-download'
export const PDF_ALL_FILES_DOWNLOAD_ROUTE_NAME = 'pdf-all-files-download'
export const ROOM_PDF_FILE_DOWNLOAD_ROUTE_NAME = 'room-pdf-file-download'
export const ROOM_FILE_DOWNLOAD_ROUTE_NAME = 'room-file-download'
/** Payment **/
export const PAYMENT_ROUTE_NAME = 'payment'
export const PAYMENT_SUCCESS_ROUTE_NAME = 'payment-success'
export const PAYMENT_CANCEL_ROUTE_NAME = 'payment-cancel'
export const PAYMENT_ROUTE_NAMES = [PAYMENT_ROUTE_NAME, PAYMENT_SUCCESS_ROUTE_NAME, PAYMENT_CANCEL_ROUTE_NAME]
/** Envelope templates */
export const ENVELOPE_TEMPLATE_ADD_ROUTE_NAME = 'envelope-template-add'
export const ENVELOPE_TEMPLATE_UPDATE_ROUTE_NAME = 'envelope-template-update'

export const LOGIN_ARRAY_ROUTE_NAME = [SIGN_IN_ROUTE_NAME, ACTIVATE_ACCOUNT_ROUTE_NAME, RESET_PASSWORD_ROUTE_NAME]
/** WOPI */
export const WOPI_PAGE_ROUTE_NAME = 'wopi-page'

/** END Routes names */

export const TOKEN_SCOPES = Object.freeze({
  SIMPLE_SIGNATURE: 'simple-signature',
  NOTIFICATIONS_PREFERENCES: 'notifications-preferences',
  FULL_ACCESS: 'full-access',
})

/**
 * Available room types
 * @readonly
 * @enum {string}
 */
// TODO : Convert to true enum during typescript file rewrite
export const ROOM_ROUTE_TYPES = Object.freeze({
  DATAROOM: 'datarooms',
  PROJECT: 'projects',
  // This type represents a type that we don't know yet and
  // thus needs to be computed after getting the room infos.
  // Used to redirect from legacy routes.
  UNDEFINED: '_',
})

Vue.use(VueRouter)

const mnemoRegexp = '[0-9A-Za-z]{13}'
const roomTypesRegexp = `${ROOM_ROUTE_TYPES.DATAROOM}|${ROOM_ROUTE_TYPES.PROJECT}|${ROOM_ROUTE_TYPES.UNDEFINED}`

const routes = [
  // LEGACY ROUTES
  // Used for routes that needs redirecting and can't support aliasing
  {
    path: '/connexion',
    redirect: '/fr/sign-in',
  },
  {
    path: '/en/login',
    redirect: '/en/sign-in',
  },
  {
    path: '/password/init/:token',
    redirect: '/fr/password/init/:token',
  },
  {
    path: '/password/reset/:token',
    redirect: '/fr/password/reset/:token',
  },
  {
    path: '/payment/stripe/:token',
    redirect: '/fr/payment/:token',
  },
  {
    path: '/pdf_sign/file/:token',
    redirect: '/pdfSign/file/:token',
  },
  {
    path: '/pdf_sign/fullfile/:token',
    redirect: '/pdfSign/fullFile/:token',
  },
  {
    path: '/pdfSign/fullfile/:token',
    redirect: '/pdfSign/fullFile/:token',
    caseSensitive: true,
  },
  {
    path: '/pdfSign/allfiles/:token',
    redirect: '/pdfSign/allFiles/:token',
    caseSensitive: true,
  },
  // The back-end adds the type to the download mail routes with mnemos but not the API
  // The route will try to download based on its URL, so we strip the type from it.
  {
    path: `/room/:roomType/:mnemo(${mnemoRegexp})/downloads/pdf/:fileId`,
    redirect: `/room/:mnemo(${mnemoRegexp})/downloads/pdf/:fileId`,
  },
  /**
    Old path with optional type. Old email path that is to be removed later on the back-end
    @since 1.40.0
  */
  {
    path: `/:roomType?/:mnemo(${mnemoRegexp})/getPdf/:fileId`,
    redirect: `/room/:mnemo(${mnemoRegexp})/downloads/pdf/:fileId`,
  },
  /**
    Old path with optional type. Old email path that is to be removed later on the back-end
    @since 1.40.0
  */
  {
    path: `/:roomType?/:mnemo(${mnemoRegexp})/getFile/:fileId`,
    redirect: `/room/:mnemo(${mnemoRegexp})/downloads/file/:fileId`,
  },
  {
    path: `/${ROOM_ROUTE_TYPES.PROJECT}/:mnemo(${mnemoRegexp})/getSignedDocuments/:token`,
    redirect: `/:mnemo(${mnemoRegexp})/getSignedDocuments/:token`,
  },
  {
    path: `/:roomType?/:mnemo(${mnemoRegexp})/getBible/:id`,
    redirect: (to) => {
      return {
        path: `${to.params.roomType || '_'}/:mnemo/bibles`,
        query: {
          idBible: to.params.id,
        },
      }
    },
  },
  {
    // Older path. No mnemo, can't go to sign but should still redirect to activate account
    path: '/password/init/:token/sign/:envelopeId',
    redirect: () => {
      return `/${window.navigator.language.split('-')[0]}/password/init/:token`
    },
  },
  {
    path: `/password/init/:token/:mnemo(${mnemoRegexp})/sign/:envelopeId`,
    redirect: (to) => {
      return {
        path: `/${window.navigator.language.split('-')[0]}/password/init/:token`,
        query: {
          envelopeIdToSign: to.params.envelopeId,
          mnemo: to.params.mnemo,
        },
      }
    },
  },
  {
    path: `/:locale/password/init/:token/:mnemo(${mnemoRegexp})/sign/:envelopeId`,
    redirect: (to) => {
      return {
        path: '/:locale/password/init/:token',
        query: {
          envelopeIdToSign: to.params.envelopeId,
          mnemo: to.params.mnemo,
        },
      }
    },
  },
  {
    path: `/:mnemo(${mnemoRegexp})/signature/:envelopeId/getSigningView/`,
    redirect: `/${ROOM_ROUTE_TYPES.PROJECT}/:mnemo(${mnemoRegexp})/:envelopeId/signing-view`,
  },
  {
    path: `/${ROOM_ROUTE_TYPES.PROJECT}/:mnemo(${mnemoRegexp})/signature/:envelopeId/getSigningView/`,
    redirect: `/${ROOM_ROUTE_TYPES.PROJECT}/:mnemo(${mnemoRegexp})/:envelopeId/signing-view`,
  },
  {
    path: `/:mnemo(${mnemoRegexp})/signature/:envelopeId/getSigningView/:token`,
    redirect: `/${ROOM_ROUTE_TYPES.PROJECT}/:mnemo(${mnemoRegexp})/signature/:envelopeId/getSigningView/:token`,
  },
  {
    path: `/:mnemo(${mnemoRegexp})/signature/:envelopeId/sign`,
    redirect: `/${ROOM_ROUTE_TYPES.PROJECT}/:mnemo(${mnemoRegexp})/signature/:envelopeId/sign`,
  },
  {
    path: `/:mnemo(${mnemoRegexp})/getSigningView/:token`,
    redirect: `/${ROOM_ROUTE_TYPES.PROJECT}/:mnemo(${mnemoRegexp})/getSigningView/:token`,
  },
  {
    path: '/logout',
    name: LOGOUT_ROUTE_NAME,
    props: true,
    component: Logout,
    meta: {
      migrated: true,
    },
  },
  {
    path: '/imAuth',
    name: IM_AUTH_ROUTE_NAME,
    component: ImAuth,
    meta: {
      requiresAuth: true,
      migrated: true,
    },
  },
  {
    path: '/saml-acs',
    name: SAML_ACS_ROUTE_NAME,
    component: SamlAcs,
    meta: {
      migrated: true,
    },
  },
  {
    path: '/',
    name: ROOMS_ROUTE_NAME,
    component: Projects,
    meta: {
      requiresAuth: true,
      migrated: true,
      title: i18n.t('common.titles.projects'),
    },
  },
  {
    path: '/profile',
    alias: '/my-profile',
    name: PROFILE_ROUTE_NAME,
    component: Profile,
    meta: {
      requiresAuth: true,
      title: i18n.t('common.titles.profil.main'),
      migrated: true,
    },
    redirect: { name: PROFILE_PERSONAL_INFORMATION_ROUTE_NAME },
    children: [
      {
        path: 'personal-information',
        name: PROFILE_PERSONAL_INFORMATION_ROUTE_NAME,
        component: PersonalInformation,
        meta: {
          title: i18n.t('common.titles.profil.personal'),
          migrated: true,
        },
      },
      {
        path: 'email-alias/:token?',
        name: PROFILE_EMAIL_ALIAS_ROUTE_NAME,
        component: EmailAlias,
        meta: {
          title: i18n.t('common.titles.profil.emailAlias'),
          migrated: true,
        },
      },
      {
        path: 'identity-checking',
        name: PROFILE_IDENTITY_CHECKING_ROUTE_NAME,
        component: IdentityChecking,
        meta: {
          title: i18n.t('common.titles.profil.idcheck'),
          migrated: true,
        },
      },
      {
        path: 'password',
        name: PROFILE_PASSWORD_ROUTE_NAME,
        component: Password,
        meta: {
          title: i18n.t('common.titles.profil.password'),
          migrated: true,
        },
      },
      {
        path: 'settings',
        name: PROFILE_SETTINGS_ROUTE_NAME,
        component: Settings,
        meta: {
          title: i18n.t('common.titles.profil.settings'),
          migrated: true,
          scopeAllowed: TOKEN_SCOPES.NOTIFICATIONS_PREFERENCES,
        },
      },
    ],
  },
  {
    path: '/subscription',
    name: SUBSCRIPTION_ROUTE_NAME,
    component: Subscription,
    redirect: { name: SUBSCRIPTION_BILLING_ROUTE_NAME },
    children: [
      {
        path: 'billing',
        name: SUBSCRIPTION_BILLING_ROUTE_NAME,
        component: SubscriptionBilling,
        meta: {
          title: i18n.t('common.titles.subscription.billing'),
          migrated: true,
        },
      },
      {
        path: 'subscribers',
        name: SUBSCRIPTION_SUBSCRIBERS_ROUTE_NAME,
        component: SubscriptionSubscribers,
        meta: {
          title: i18n.t('common.titles.subscription.subscribers'),
          migrated: true,
        },
      },
      {
        path: 'teams',
        name: SUBSCRIPTION_TEAMS_ROUTE_NAME,
        component: SubscriptionTeams,
        meta: {
          title: i18n.t('common.titles.subscription.teams'),
          migrated: true,
        },
      },
      {
        path: 'projects',
        name: SUBSCRIPTION_PROJECTS_ROUTE_NAME,
        component: SubscriptionProjects,
        meta: {
          title: i18n.t('common.titles.subscription.projects'),
          migrated: true,
        },
      },
      {
        path: 'closed-projects',
        name: SUBSCRIPTION_CLOSED_PROJECTS_ROUTE_NAME,
        component: SubscriptionClosedProjects,
        meta: {
          title: i18n.t('common.titles.subscription.closedProjects'),
          migrated: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
      title: i18n.t('common.titles.subscription.main'),
      migrated: true,
    },
  },
  {
    path: '/archives',
    component: ArchivesLayout,
    meta: {
      requiresAuth: true,
      title: i18n.t('common.titles.archives'),
    },
    children: [
      {
        path: '',
        name: ARCHIVES_ROUTE_NAME,
        component: Archives,
        meta: {
          migrated: true,
        },
      },
      {
        path: 'add',
        name: ARCHIVES_ADD_ROUTE_NAME,
        component: ArchivesAdd,
        props: true,
        meta: {
          migrated: true,
        },
      },
    ],
  },
  {
    path: '/folder/:folderId',
    name: FOLDER_ROUTE_NAME,
    component: Projects,
    meta: {
      requiresAuth: true,
      migrated: true,
    },
    props: true,
    children: [
      {
        path: ':childId?',
        name: FOLDER_ROUTE_NAME,
        component: Projects,
        meta: {
          migrated: true,
        },
        props: true,
      },
    ],
  },
  {
    path: `/${ROOM_ROUTE_TYPES.DATAROOM}/:mnemo(${mnemoRegexp})/nda/signing-view/`,
    props: (route) => ({ ...route.params, isNda: true }),
    name: NDA_SIGNING_VIEW_ROUTE_NAME,
    component: SigningView,
    meta: {
      requiresAuth: true,
      migrated: true,
    },
  },
  // IMPORTANT: Put all routes at the top level before the project_route
  // Because of the legacy alias that's dynamic, we need all static routes
  // That also match to be before it to avoid problems at init
  {
    path: `/:roomType(${roomTypesRegexp})/:mnemo(${mnemoRegexp})`,
    name: ROOM_ROUTE_NAME,
    component: Project,
    meta: {
      requiresAuth: true,
    },
    props: true,
    children: [
      {
        path: 'settings',
        name: ROOM_SETTINGS_ROUTE_NAME,
        props: true,
        component: ProjectSettings,
        meta: {
          migrated: true,
        },
      },
      {
        path: 'members',
        name: ROOM_MEMBERS_ROUTE_NAME,
        props: true,
        component: Members,
        meta: {
          migrated: true,
        },
      },
      {
        path: 'documents',
        name: ROOM_DOCUMENTS_ROUTE_NAME,
        props: true,
        component: Documents,
        meta: {
          migrated: true,
        },
      },
      {
        path: 'watermark-view/:fileId',
        alias: 'watermark/:fileId',
        name: ROOM_WATERMARK_VIEW_ROUTE_NAME,
        props: true,
        component: WatermarkView,
        meta: {
          migrated: true,
        },
      },
      {
        path: 'checklist',
        alias: 'agenda',
        name: ROOM_CHECKLIST_ROUTE_NAME,
        props: true,
        component: Checklist,
        meta: {
          migrated: true,
        },
      },
      {
        path: 'checklist/validate/deletion',
        name: ROOM_CHECKLIST_DELETION_VALIDATION_ROUTE_NAME,
        props: true,
        component: Checklist,
        meta: {
          migrated: true,
        },
      },
      {
        path: 'signing-checklist',
        alias: 'signing-guide',
        props: true,
        component: SigningChecklistLayout,
        children: [
          {
            path: '',
            props: true,
            name: SIGNING_CHECKLIST_ROUTE_NAME,
            component: SigningChecklist,
            meta: {
              migrated: true,
            },
          },
          {
            path: 'add',
            props: true,
            name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
            component: SigningChecklistAdd,
            meta: {
              migrated: true,
            },
          },
          {
            path: 'order',
            props: true,
            name: SIGNING_CHECKLIST_ORDER_ROUTE_NAME,
            component: SigningChecklistOrder,
            meta: {
              migrated: true,
            },
          },
        ],
      },
      {
        path: ':envelopeId/signing-view/:token?',
        props: true,
        name: SIGNING_VIEW_ROUTE_NAME,
        component: SigningView,
        meta: {
          scopeAllowed: TOKEN_SCOPES.SIMPLE_SIGNATURE,
          migrated: true,
        },
      },
      {
        path: 'signed-documents',
        alias: 'my-signatures',
        name: ROOM_SIGNED_DOCUMENTS_ROUTE_NAME,
        props: true,
        component: SignedDocuments,
        meta: {
          migrated: true,
        },
      },
      {
        path: 'bibles',
        name: ROOM_BIBLES_ROUTE_NAME,
        props: true,
        component: Bibles,
        meta: {
          migrated: true,
        },
      },
      {
        path: 'qa',
        props: true,
        component: QuestionAnswerLayout,
        children: [
          {
            path: '',
            props: true,
            name: QUESTION_ANSWER_LIST_ROUTE_NAME,
            component: QuestionAnswerList,
            meta: {
              migrated: true,
            },
          },
          {
            path: 'add',
            props: true,
            name: QUESTION_ANSWER_ADD_ROUTE_NAME,
            component: QuestionAnswerAdd,
            meta: {
              migrated: true,
            },
          },
          {
            path: ':questionId',
            props: true,
            name: QUESTION_ANSWER_ITEM_ROUTE_NAME,
            component: QuestionAnswerItem,
            meta: {
              migrated: true,
            },
          },
        ],
      },
      {
        path: 'logs',
        name: ROOM_LOGS_ROUTE_NAME,
        props: true,
        component: Logs,
        meta: {
          migrated: true,
        },
      },
      {
        path: 'envelope-template',
        component: EnvelopeTemplateLayout,
        children: [
          {
            path: 'add',
            name: ENVELOPE_TEMPLATE_ADD_ROUTE_NAME,
            component: EnvelopeTemplateAdd,
            props: { formMode: 'creation' },
            meta: {
              migrated: true,
            },
          },
          {
            path: 'update',
            name: ENVELOPE_TEMPLATE_UPDATE_ROUTE_NAME,
            component: EnvelopeTemplateAdd,
            props: { formMode: 'edition' },
            meta: {
              migrated: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: `/${ROOM_ROUTE_TYPES.PROJECT}/:mnemo(${mnemoRegexp})/signature/:envelopeId/sign`,
    name: CERTEUROPE_SIGN_ROUTE_NAME,
    props: true,
    component: CerteuropeSign,
    meta: {
      requiresAuth: false,
      migrated: true,
    },
  },
  {
    path: `/signature/landing/:mnemo(${mnemoRegexp})?`,
    alias: `/signature/landing/${ROOM_ROUTE_TYPES.PROJECT}/:mnemo(${mnemoRegexp})`,
    name: SIGN_LANDING_PAGE_ROUTE_NAME,
    props: (route) => ({ ...route.query, ...route.params }),
    component: LandingPage,
    meta: {
      requiresAuth: false,
      migrated: true,
    },
  },
  // NO-AUTH PATHS
  // They need a locale param to help set a display language.
  // The parent route template only renders the actual route.
  {
    path: '/:locale([A-Za-z]{2})',
    component: { render (h) { return h('router-view') } },
    name: NO_AUTH_ROUTE_NAME,
    props: true,
    beforeEnter: (to, from, next) => {
      // The payment routes are an exception that requires the locale and can be used logged in or not logged in
      if ((axios.defaults.headers.common.Authorization || window.$cookies.get(AUTH_DATA_COOKIE_KEY)) && !PAYMENT_ROUTE_NAMES.includes(to.name)) {
        if (to.name === ACTIVATE_ACCOUNT_ROUTE_NAME) {
          return next({
            name: LOGOUT_ROUTE_NAME,
            params: {
              redirectPath: to.path,
            },
          })
        }
        return next('/')
      }
      const locale = to.params.locale?.substr(0, 2)
      const supportedLocales = i18n.availableLocales
      if (!supportedLocales.includes(locale)) {
        i18n.locale = 'en'
        to.params.locale = 'en'
        return next({ ...to, params: { ...to.params, locale: 'en' } })
      } else if (i18n.locale !== locale) {
        i18n.locale = locale
      }
      return next()
    },
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: 'sign-in',
        name: SIGN_IN_ROUTE_NAME,
        props: (route) => ({ ...route.query, ...route.params }),
        component: SignIn,
        meta: {
          title: i18n.t('common.titles.auth.main'),
          migrated: true,
        },
      },
      {
        path: 'password/init/:token',
        name: ACTIVATE_ACCOUNT_ROUTE_NAME,
        component: ActivateAccount,
        props: true,
        meta: {
          requiresAuth: false,
          title: i18n.t('common.titles.auth.activate'),
          migrated: true,
        },
      },
      {
        path: 'password/reset',
        name: FORGOT_PASSWORD_ROUTE_NAME,
        component: ForgotPassword,
        meta: {
          requiresAuth: false,
          title: i18n.t('common.titles.auth.forgot'),
          migrated: true,
        },
      },
      {
        path: 'password/reset/:token',
        name: RESET_PASSWORD_ROUTE_NAME,
        component: ResetPassword,
        props: true,
        meta: {
          requiresAuth: false,
          title: i18n.t('common.titles.auth.reset'),
          migrated: true,
        },
      },
      {
        path: 'payment/success',
        name: PAYMENT_SUCCESS_ROUTE_NAME,
        component: PaymentSuccess,
        props: true,
        meta: {
          migrated: true,
          requiresAuth: false,
        },
      },
      {
        path: 'payment/cancel',
        name: PAYMENT_CANCEL_ROUTE_NAME,
        component: PaymentCancel,
        props: true,
        meta: {
          migrated: true,
          requiresAuth: false,
        },
      },
      {
        path: 'payment/:token',
        name: PAYMENT_ROUTE_NAME,
        component: Payment,
        props: true,
        meta: {
          migrated: true,
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/two-factor',
    name: TWO_FACTOR_ROUTE_NAME,
    component: TwoFactor,
    props: true,
    meta: {
      requiresAuth: false,
      title: i18n.t('common.titles.auth.dfa'),
      migrated: true,
    },
  },
  {
    path: `/${ROOM_ROUTE_TYPES.PROJECT}/:mnemo(${mnemoRegexp})/signature/:envelopeId/getSigningView/:token`,
    name: SIMPLE_SIGNATURE_ROUTE_NAME,
    component: AuthByToken,
    props: true,
    meta: {
      requiresAuth: false,
      migrated: true,
    },
  },
  {
    path: '/:token/notifications',
    name: NOTIFICATIONS_PREFERENCES_ROUTE_NAME,
    component: AuthByToken,
    props: true,
    meta: {
      requiresAuth: false,
      migrated: true,
    },
  },
  {
    path: `/room/:mnemo(${mnemoRegexp})/downloads/pdf/:fileId`,
    name: ROOM_PDF_FILE_DOWNLOAD_ROUTE_NAME,
    component: DownloadFromToken,
    props: true,
    meta: {
      requiresAuth: true,
      migrated: true,
    },
  },
  {
    path: `/room/:mnemo(${mnemoRegexp})/downloads/file/:fileId`,
    name: ROOM_FILE_DOWNLOAD_ROUTE_NAME,
    component: DownloadFromToken,
    props: true,
    meta: {
      requiresAuth: true,
      migrated: true,
    },
  },
  {
    path: `/room/:mnemo(${mnemoRegexp})/wopi/file/:fileId/:mode`,
    name: WOPI_PAGE_ROUTE_NAME,
    component: WopiPage,
    props: true,
    meta: {
      requiresAuth: false,
      migrated: true,
    },
  },
  {
    path: `/:mnemo(${mnemoRegexp})/getSignedDocuments/:token`,
    name: SIGNED_DOCUMENT_DOWNLOAD_ROUTE_NAME,
    component: DownloadFromToken,
    props: true,
    meta: {
      requiresAuth: false,
      migrated: true,
    },
  },
  {
    path: '/pdfSign/allFiles/:token',
    name: PDF_ALL_FILES_DOWNLOAD_ROUTE_NAME,
    component: DownloadFromToken,
    props: true,
    meta: {
      requiresAuth: false,
      migrated: true,
    },
  },
  {
    path: '/pdfSign/fullFile/:token',
    name: PDF_FULL_FILE_DOWNLOAD_ROUTE_NAME,
    component: DownloadFromToken,
    props: true,
    meta: {
      requiresAuth: false,
      migrated: true,
    },
  },
  {
    path: '/pdfSign/file/:token',
    name: PDF_FILE_DOWNLOAD_ROUTE_NAME,
    component: DownloadFromToken,
    props: true,
    meta: {
      requiresAuth: false,
      migrated: true,
    },
  },
  // Variant from an email, no specific page accessed
  {
    path: `/:mnemo(${mnemoRegexp})`,
    redirect: `/${ROOM_ROUTE_TYPES.UNDEFINED}/:mnemo/`,
  },
  {
    path: `/:mnemo(${mnemoRegexp})/*`,
    redirect: `/${ROOM_ROUTE_TYPES.UNDEFINED}/:mnemo/*`,
  },
  {
    path: `/project/:mnemo(${mnemoRegexp})/*`,
    redirect: `/${ROOM_ROUTE_TYPES.UNDEFINED}/:mnemo/*`,
  },
  {
    path: '/:pathMatch(.*)',
    name: NOT_FOUND_ROUTE_NAME,
    component: NotFound,
    props: true,
    meta: {
      requiresAuth: false,
      migrated: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// If we don't have a value for it, we assume it's disabled by default for V2.
// We will _have_ to opt-in for it by setting a string value different to true.
const bridgeDisabled = !process.env.VUE_APP_DISABLE_NEW_VERSION_BRIDGE || process.env.VUE_APP_DISABLE_NEW_VERSION_BRIDGE === 'true'

if (window.location.port !== process.env.VUE_APP_DEV_SERVER_PORT && !bridgeDisabled) {
  router.beforeEach((to, from, next) => {
    // Individual routes can still opt-out when navigating through the runtime by using the param "forceV2".
    if (to.meta?.migrated && !to.params?.forceV2) {
      const targetFullPath = to.fullPath
      const finalUrlPath = window.location.protocol + '//' + window.location.host + '/v3' + targetFullPath
      if (store.state.appUploadHandler.uploadInProgress !== 0) {
        // In that case, we can't have the current task be cancelled. Open a new tab !
        // TODO: make the process smoother by providing an actual UI later.
        window.open(finalUrlPath, '_blank')
      } else {
        // Force a loading state
        store.commit('global/' + SET_APP_STATE, AppStateEnum.loading)
        window.location.href = finalUrlPath
      }
    } else {
      next()
    }
  })
}

router.beforeEach((to, from, next) => {
  try {
    // See V2-1636. This is the hash used from Pendo to know we want to show the
    // dataroom contact form in-app
    if (to.hash === '#dataroom-info') {
      // DO THE THING
      // Reset the hash
      location.hash = ''
      store.commit('subscription/' + SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG, {
        defaultProjectType: QUOTE_REQUEST_TOPICS.DATAROOM,
        isOpen: true,
      })
    } else {
      store.commit(SET_ROUTER_HISTORY, from.name)
    }
  } catch (e) {
    console.error(e)
  }
  next()
})

router.afterEach(async (to, from) => {
  try {
    Vue.nextTick(async () => {
      const title = to.meta.title
      if (title) {
        document.title = title + ' - Closd'
      }
    })
  } catch (e) {
    console.error(e)
    console.info('Couldn\'t tell HappyFox a navigation occurred')
  }
})

export default router
